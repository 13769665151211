
<template>
    <section class="zc-calendario w-100 overflow-auto scroll-old fadeIn">
        <div class="col-11">
            <FullCalendar :options="calendarOptions" class="fullcalendar-styles" />
        </div>
    </section>
</template>
<script>
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ZonasReservas from '~/services/reservas'
export default {
    props: {
        idZona: {
            type: Number,
            default: null
        }
    },
    data(){
        return {
            inputSearch: "",
            mostrarRequierenPago: false,
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                locale: "es",
                selectable: true,
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "",
                },
                buttonText: {
                    today: "Hoy",
                },
                // dateClick: function (info) {
                //   alert("clicked " + info.dateStr);
                // },
                eventClick:  (info)  => {
                    window.open(`/zonas/reservas/${info.event.id}`, '_self')
                    // window.open("/zonas/reservas/id", "_self");
                },
                dayMaxEvents: true,
                eventDisplay: "list-item",
                events: [],
            },
            zonaId: null
        };
    },
    watch:{
        idZona(value){
            if (value){
                this.zonaId = value;
                this.verListadoReservas();
            }
        },
    },
    mounted(){
        this.verListadoReservas()
    },
    methods: {
        async verListadoReservas(){
            try {
                const { data } = await ZonasReservas.ReservasPendientes({idZona: this.zonaId});
                if (data.success){
                    this.calendarOptions.events = data.data;                    
                }
            } catch (error){
                this.errorCatch(error)
            }
        },
        handleDateClick(){
            console.log("clicked");
        },
    },
};
</script >
<style lang="scss" scoped>
.zc-calendario {
  height: calc(100vh - 200px);
}
</style>
